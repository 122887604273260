<template>
  <div>
    <search-funeral-service-point @submit="submitSearch" />

    <div class="custom-table-operation-buttons">
      <a-button type="primary" @click="showNewModal" html-type="button">
        新增
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增殡仪服务点模态框 -->
    <new-funeral-service-point
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑殡仪服务点模态框 -->
    <edit-funeral-service-point
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="FuneralServicePoint"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import SearchFuneralServicePoint from '@/views/funeral_service_points/Search'
import Pagination from '@/components/Pagination'
import {
  findFuneralServicePoints,
  updateFuneralServicePointEffective
} from '@/api/funeral_service_point'

export default {
  name: 'FuneralServicePointList',
  components: {
    Pagination,
    SearchFuneralServicePoint,
    NewFuneralServicePoint: () => import('@/views/funeral_service_points/New'),
    EditFuneralServicePoint: () => import('@/views/funeral_service_points/Edit'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '殡仪服务点名称',
          width: 170,
          dataIndex: 'name'
        },
        {
          title: '地址',
          width: 170,
          dataIndex: 'address'
        },
        {
          title: '经度',
          width: 160,
          dataIndex: 'longitude'
        },
        {
          title: '纬度',
          width: 160,
          dataIndex: 'latitude'
        },
        {
          title: '状态',
          width: 150,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '介绍',
          dataIndex: 'remark',
          width: 190,
          ellipsis: true
        },
        {
          title: '操作',
          width: 220,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    // 更新事件
    showNewModal() {
      this.isShowNewModal = true
    },

    // 编辑事件
    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findFuneralServicePoints(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    // 改变有效性的
    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定进行生效吗?' : '确定进行失效吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateFuneralServicePointEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    }
  }
}
</script>
