import request from '@/utils/request'

// 更新殡仪服务点
export function updateFuneralServicePoint(funeralServicePointId, data) {
  return request({
    url: `/funeral_service_points/${funeralServicePointId}`,
    method: 'put',
    data
  })
}

// 更新殡仪服务点有效性
export function updateFuneralServicePointEffective(funeralServicePointId, data) {
  return request({
    url: `/funeral_service_points/${funeralServicePointId}/effective`,
    method: 'patch',
    data
  })
}

// 添加殡仪服务点
export function createFuneralServicePoint(data) {
  return request({
    url: `/funeral_service_points`,
    method: 'post',
    data
  })
}

// 查询所有殡仪服务点
export function findFuneralServicePoints(params) {
  return request({
    url: `/funeral_service_points`,
    method: 'get',
    params: params
  })
}

